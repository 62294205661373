// UI elements functionality
import $ from 'jquery'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {isMobile} from './helpers/helper'
import {formSend} from './contact'
import {advertorialSlider, mobileRowSlider, articleSlider} from './sliders'
import './plugins/lightgallery'

// register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const funcInBind = () => {
  $('.calendar-col-item').on('click', e => {
    if (e) e.preventDefault()
    if (isMobile()) {
      $(e.currentTarget).toggleClass('active')
    }
  })

  $('.calendar-prev-btn').on('click', e => {
    if (e) e.preventDefault()
    const yearVal = parseInt($('#calendar-year').val())

    $('#calendar-year').val(yearVal - 1)

    calendarRequest()
  })

  $('.calendar-next-btn').on('click', e => {
    if (e) e.preventDefault()
    const yearVal = parseInt($('#calendar-year').val())

    $('#calendar-year').val(yearVal + 1)

    calendarRequest()
  })

  $('.drop-down-icon').on('click', e =>
    $(e.currentTarget)
      .parent()
      .find('.mobile-drop-down')
      .toggleClass('mobile-drop-down-active'),
  )
}

// header logic
const headerHide = () => {
  if (!isMobile()) {
    const showAnim = gsap
      .from('#header', {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1)

    ScrollTrigger.create({
      trigger: 'body',
      start: '0% 0%',
      end: '100% 100%',
      // markers: true,
      onUpdate: self => {
        if (self.direction === -1) {
          showAnim.play()
        } else {
          if ($('.header.open-supermenu').length) {
            menuClose()
          }

          if ($('.header.open-search').length) {
            searchClose()
          }
          showAnim.reverse()
        }
      },
    })
  }
}

const headerMenuHandler = () => {
  $('#menu-btn').on('click', e => {
    if (e) e.preventDefault()
    const self = $(e.currentTarget)
    const header = $('#header')

    if (!self.hasClass('active') && !self.hasClass('start-anim')) {
      self.addClass('active start-anim')
      setTimeout(function () {
        self.addClass('rotate-lines')
      }, 300)

      setTimeout(function () {
        self.removeClass('start-anim')
      }, 1000)

      header.addClass('open-supermenu')
      searchClose()
    } else {
      menuClose()
    }
  })

  $('.mobile-sub-nav-btn').on('click', e => {
    if (e) e.preventDefault()
    const self = $(e.currentTarget)

    if (!self.parent().hasClass('active')) {
      self.parent().addClass('active').siblings().removeClass('active')
    } else {
      self.parent().removeClass('active')
    }
  })

  $('.mobile-sub-nav-container').on('click', '.close-btn', e => {
    if (e) e.preventDefault()
    $('.mobile-main-nav-list-item').removeClass('active')
  })
}

const menuClose = () => {
  const self = $('#menu-btn')
  const header = $('#header')

  self.removeClass('rotate-lines')
  header.removeClass('open-supermenu')

  setTimeout(function () {
    self.removeClass('active')
  }, 300)
}

const headerSearchHandler = () => {
  $('#header-search-btn').on('click', e => {
    if (e) e.preventDefault()
    const self = $(e.currentTarget)
    const header = $('#header')

    if (!self.hasClass('active')) {
      self.addClass('active')
      header.addClass('open-search')
      menuClose()
    } else {
      searchClose()
    }
  })

  $('#header-close-search').on('click', e => {
    if (e) e.preventDefault()
    searchClose()
  })
}
const searchClose = () => {
  const self = $('#header-search-btn')
  const header = $('#header')

  self.removeClass('active')
  header.removeClass('open-search')
}

const loginHandler = () => {
  const popup = $('#login-popup')
  $('.login-btn').on('click', e => {
    if (e) e.preventDefault()

    popup.fadeIn().addClass('active')
    $('html,body').addClass('hidden')
  })

  $('#login-close').on('click', e => {
    if (e) e.preventDefault()
    popup.removeClass('active').fadeOut()
    $('html,body').removeClass('hidden')
  })
}

const articlePopup = () => {
  const popup = $('#article-popup')
  $('.login-btn').on('click', e => {
    if (e) e.preventDefault()

    popup.fadeIn().addClass('active')
    $('html,body').addClass('hidden')
  })

  $('#article-close').on('click', e => {
    if (e) e.preventDefault()
    popup.removeClass('active').fadeOut()
    $('html,body').removeClass('hidden')
  })
}

//////////////////////Esc close/////////////////////////////

const escClose = () => {
  $(document).on('keydown', e => {
    if (e.keyCode == 27) {
      // console.log('hit esc')
      if ($('.header.open-supermenu').length) {
        menuClose()
      }

      if ($('.header.open-search').length) {
        searchClose()
      }

      if ($('#login-popup.active').length) {
        $('#login-popup').removeClass('active').fadeOut()
        $('html,body').removeClass('hidden')
      }

      if ($('#article-popup.active').length) {
        $('#article-popup').removeClass('active').fadeOut()
        $('html,body').removeClass('hidden')
      }
    }
  })
}
///////////////////sticky aside //////////////
export const stickyBar = () => {
  if (!$('.aside-grid-section').length) return
  if ($(document).width() < 720) return

  $('.aside-grid-section').each((index, element) => {
    const aside_section = $(element)
    const pin_el = $(element).find('.aside-pin-el')
    const header_height = $('#header').outerHeight()

    ScrollTrigger.create({
      trigger: aside_section,
      // markers: true,
      start: `top top+=${header_height}`,
      end: () => `+=${aside_section.outerHeight() - pin_el.outerHeight()}`,
      pin: pin_el,
      pinSpacing: false,
      scrub: 1,
    })
  })
}

export const stickyMid = () => {
  if (!$('.section-grid-col.aside-right-col').length) return
  if ($(document).width() < 720) return

  if (
    window.innerHeight - $('#header').outerHeight() <
    $('.sticky-wrap').outerHeight()
  ) {
    return
  }

  $('.section-grid-col.aside-right-col').each((index, element) => {
    const aside_section = $(element)
    const pin_el = $(element).find('.sticky-wrap')
    const top_right = $('.aside-right-col-top').outerHeight()
    const header_height = $('#header').outerHeight()
    const banner_container = aside_section.find('.aside-pin-el-mid')

    // console.log(aside_section, 'aside section')
    // console.log(top_right, 'top-right')
    // console.log(header_height, 'header height')

    // console.log(pin_el.parent())
    ScrollTrigger.create({
      trigger: pin_el,
      // markers: true,
      start: `top top+=${header_height}`,
      end: () =>
        `+=${aside_section.outerHeight() - pin_el.outerHeight() - top_right}`,
      pin: pin_el,
      pinSpacing: false,
    })

    // ScrollTrigger.create({
    //   trigger: pin_el.parent(),
    //   markers: true,
    //   start: `30% 60%`,
    //   end: () => `100% 100%`,
    //   endTrigger: '.content-col',
    //   pin: '.sticky-wrap',
    //   pinSpacing: false,
    // })
  })
}

const pagesInit = () => {
  if ($('.home-page').length) {
    import(/* webpackChunkName: 'chunks/home_index' */ './pages/home.js').then(
      module => {
        module.homeInit()
        // ScrollTrigger.refresh()
      },
    )
  }
}

//most -news section
const tabMenu = () => {
  $('.tab-button').on('click', function () {
    $('.tab-button').removeClass('active')
    $(this).addClass('active')
    $('.tab-container').removeClass('active')
    let num = $(this).attr('data-tab')

    var popUpContent = $('.tab-content-' + num)
    popUpContent.addClass('active')
  })
}

// calendar year table/////
const calendarRequest = () => {
  const form = $('#calendar-form')
  const form_fields = form.serialize()
  const url = form.attr('action')
  const container = $('.calendar-grid-holder')

  container.addClass('loading')

  $.ajax({
    url: url,
    dataType: 'html',
    type: 'post',
    data: form_fields,

    success: function (data) {
      setTimeout(function () {
        container.html('').append(data)
        container.removeClass('loading')
      }, 400)
    },
  })
}

const initLightGallery = () => {
  if ($('#lightgallery').length) {
    // console.log('initLighgallery')

    var $lg = $('#lightgallery')
    $lg.lightGallery({
      selector: '.media-thumb',
      hash: false,
      speed: 1000,
      videojs: true,
      // mode: 'lg-slide-vertical-growth',
      thumbnail: false,
      zoom: false,
      share: false,
      fullScreen: false,
      autoplayControls: false,
      mode: 'lg-slide',
      cssEasing: 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
    })
  }
}

const scrollTo = () => {
  $('.scroll-to-btn').on('click', function (e) {
    if (e) e.preventDefault()
    var self = $(this),
      section_id = self.attr('data-scroll-to'),
      section_offset = $(section_id).offset().top,
      page = $('html, body')
    setTimeout(function () {
      page.animate(
        {
          scrollTop: section_offset - $('#header').height(),
        },
        600,
      )
    })
  })
}

const controlBtn = () => {
  if ($('#control-button').length) {
    var isPlaying = false
    var av = document.getElementById('player')
    var content = document.getElementById('control-button')
    $('#control-button').on('click', () => {
      if (isPlaying) {
        av.pause()
        isPlaying = false
        content.innerHTML = '►'
      } else {
        av.play()
        isPlaying = true
        content.innerHTML = '❚❚'
      }
    })
  }
}

export const UI = () => {
  scrollTo()
  initLightGallery()
  pagesInit()
  formSend()
  headerMenuHandler()
  escClose()
  headerSearchHandler()
  loginHandler()
  articlePopup()
  stickyBar()
  headerHide()
  tabMenu()
  if (isMobile()) {
    advertorialSlider()
  }
  articleSlider()
  mobileRowSlider()
  funcInBind()
  stickyMid()
  controlBtn()
}
