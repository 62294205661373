import $ from 'jquery'
import Swiper from 'swiper/bundle'
import {isMobile} from './helpers/helper'

export const singleSlideSlider = () => {
  if (!$('.row-slider').length) return

  $('.row-slider').each((index, element) => {
    const total_slides = $(element).find('.swiper-slide').length
    const slider_prev = $(element)
      .closest('.section-block-row')
      .find('.slider-prev')
      .addClass(`template-prev-${index}`)
    const slider_next = $(element)
      .closest('.section-block-row')
      .find('.slider-next')
      .addClass(`template-next-${index}`)

    var swiper = new Swiper(element, {
      slidesPerView: 'auto',
      speed: 800,
      watchSlidesVisibility: 1,
      slideActiveClass: 'active',
      observer: true,
      observeParents: true,
      loop: true,
      loopedSlides: total_slides,
      navigation: {
        nextEl: `.template-next-${index}`,
        prevEl: `.template-prev-${index}`,
      },
      autoplay: {
        delay: 4500 + index * 500,
      },
    })

    if ($(element).hasClass('autoplay-false')) {
      swiper.autoplay.stop()
    }
  })
}
export const advertorialSlider = () => {
  if (!$('.advertorial-holder.slider').length) return
  $('.advertorial-holder.slider').addClass('swiper-container')
  $('.advertorial-inner.slider').addClass('swiper-wrapper')
  $('.advert-item.slider').addClass('swiper-slide')
  let swiper = new Swiper('.advertorial-holder.slider', {
    slidesPerView: 2.2,
    speed: 600,
    observer: true,
    observeParents: true,
    spaceBetween: 11,
    // loop: true,
    // loopedSlides: total_slides,
    navigation: {
      nextEl: '.slider-next',
      prevEl: '.slider-prev',
    },
    // autoplay: {
    //   delay: 1000,
    // },
  })
}

export const mobileRowSlider = () => {
  if (!$('.js-mobile-row-slider').length) return
  if ($(document).width() > 720) return

  $('.js-mobile-row-slider').each((index, element) => {
    const slider_prev = $(element)
      .closest('.mobile-slider-unit')
      .find('.slider-prev')
      .addClass(`template-mobile-prev-${index}`)
    const slider_next = $(element)
      .closest('.mobile-slider-unit')
      .find('.slider-next')
      .addClass(`template-mobile-next-${index}`)

    // console.log('mobile-row-slider')
    $('.js-mobile-row-slider').addClass('swiper-container')
    $('.js-mobile-row-slider-wrap').addClass('swiper-wrapper')
    $('.js-mobile-row-slide').addClass('swiper-slide')
    let swiper = new Swiper(element, {
      slidesPerView: 2.2,
      speed: 600,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: `.template-mobile-next-${index}`,
        prevEl: `.template-mobile-prev-${index}`,
      },
    })
  })
}
export const colSlider = () => {
  if (!$('.col-slider').length) return

  $('.col-slider').each((index, element) => {
    const total_slides = $(element).find('.swiper-slide').length
    const slider_prev = $(element)
      .closest('.section-block-row')
      .find('.slider-prev')
      .addClass(`template-prev-${index}`)
    const slider_next = $(element)
      .closest('.section-block-row')
      .find('.slider-next')
      .addClass(`template-next-${index}`)

    if (isMobile()) {
      mobileLayout()
      colSliderInit()
    } else {
      colSliderInit()
    }

    // return

    function mobileLayout() {
      const el = $(element).find('.col-slide-m')
      el.addClass('swiper-slide')
      el.slice(0).appendTo('.col-slider-wrap')
      $('.col-slide-d').remove()
    }

    function colSliderInit() {
      return new Swiper(element, {
        slidesPerView: 'auto',
        speed: 800,
        watchSlidesVisibility: 1,
        slideActiveClass: 'active',
        observer: true,
        observeParents: true,
        loop: true,
        loopedSlides: total_slides,
        navigation: {
          nextEl: `.template-next-${index}`,
          prevEl: `.template-prev-${index}`,
        },
        autoplay: {
          delay: 4500 + index * 500,
        },
      })
    }
  })
}

// Single article gallery
export const articleSlider = () => {
  var swiper = new Swiper('.thumbs-gallery', {
    spaceBetween: 10,
    slidesPerView: 4.5,
    freeMode: true,
    watchSlidesProgress: true,
    observer: true,
  })
  var swiper2 = new Swiper('.article-gallery', {
    spaceBetween: 10,
    observer: true,
    navigation: {
      nextEl: '.article-button-next',
      prevEl: '.article-button-prev',
    },
    thumbs: {
      swiper: swiper,
    },
  })
}
