import $ from 'jquery'

export const formSend = () => {
  $('.template-form').on('submit', e => {
    e.preventDefault()
    const self = $(e.currentTarget),
      form_fields = self.serialize(),
      url = self.attr('action')
    // console.log(form_fields)

    self.addClass('loading')

    $.ajax({
      url: url,
      dataType: 'json',
      type: 'post',
      data: form_fields,

      success: function (data) {
        if (data.status == true) {
          successMsg(data)
        } else {
          checkForError(data)
        }
      },
    })

    const checkForError = data => {
      setTimeout(function () {
        self.removeClass('loading')
      }, 1000)

      $.each(data.errors, function (key, val) {
        var $error_label = $('#' + key).closest('.form-item')
        $error_label.addClass('error-fld').find('.box-error').html(val)
      })
    }

    const successMsg = data => {
      if (self.hasClass('login-form')) {
        window.location.href = data.redirectUrl
        return
      }

      $('.box-success').addClass('active')
      $('.success-message-container').addClass('active')

      setTimeout(function () {
        $('.template-form input, .template-form textarea,.template-form select')
          .val('')
          .trigger('input')
        // $('#contact-form select').prop('selectedIndex',0);
      }, 1000)

      setTimeout(function () {
        $('.box-success').removeClass('active')
        self.removeClass('loading')
      }, 3000)
    }
  })

  $('.form-fld-contact').on('focus change', e => {
    const form_fld = $(e.currentTarget).closest('.form-item')

    if (form_fld.hasClass('error-fld')) {
      form_fld.removeClass('error-fld')
    }
  })
}

//on success reset recapcha when you add it
